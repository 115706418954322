import { type ActionReducerMapBuilder, createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../configuration/setup/store';
import { type AddressSuggestion, hereAutoSuggestApi } from '../services/here/hereAutoSuggestApi';
import { transportOrdersApi } from '../services/orderExchange/orderExchange.api';
import type { TransportOrder, TransportOrderList } from '../services/orderExchange/orderExchange.types';

export type AppState = {
    sessionExpiredAcknowledged: boolean;
    transportOrders?: TransportOrder[];
    addressSuggestions?: AddressSuggestion[];
    selectedTransportOrderId?: string;
    showOrderList: boolean;
    searchValue: string;
};

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    transportOrders: undefined,
    addressSuggestions: undefined,
    selectedTransportOrderId: undefined,
    showOrderList: true,
    searchValue: '',
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: state => {
            state.sessionExpiredAcknowledged = true;
        },
        transportOrderSelected: (state, action: PayloadAction<string | undefined>) => {
            state.selectedTransportOrderId = action.payload;
        },
        addressSuggestionsStored: (state, action: PayloadAction<AddressSuggestion[]>) => {
            state.addressSuggestions = action.payload;
        },
        showOrderListChanged: (state, action: PayloadAction<boolean>) => {
            state.showOrderList = action.payload;
        },
        searchValueChanged: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<AppState>) => {
        builder.addMatcher(
            transportOrdersApi.endpoints.getOrders.matchFulfilled,
            (state, action: PayloadAction<TransportOrderList>) => {
                state.transportOrders = action.payload.items;
            }
        );
        builder.addMatcher(
            hereAutoSuggestApi.endpoints.fetchAddressSuggestions.matchFulfilled,
            (state, action: PayloadAction<AddressSuggestion[]>) => {
                state.addressSuggestions = action.payload;
            }
        );
    },
});

export const { hideSessionExpiredDialog, transportOrderSelected, showOrderListChanged, searchValueChanged } =
    appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;

export const getSelectedTransportOrderId = (state: RootState) => state.app.selectedTransportOrderId;

export const getOrderList = (state: RootState) => state.app.transportOrders;

export const getShowOrderList = (state: RootState) => state.app.showOrderList;

export const getSearchValue = (state: RootState) => state.app.searchValue;

export const getSelectedTransportOrder = createSelector(
    getOrderList,
    getSelectedTransportOrderId,
    (transportOrders, selectedTransportOrderId) =>
        transportOrders?.find(transportOrder => transportOrder.id === selectedTransportOrderId)
);

export default appSlice.reducer;
